import HantQuoteboxTheme from '@/hant/components/Quotebox/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserTheme from 'base/components/ArticleTeaser/theme';

export default Object.assign(
  tw.theme({
    extend: BaseArticleTeaserTheme,
    slots: {
      base: ['group-[&:not(.bg)]/box:bg-white', 'rounded-2xl', 'overflow-hidden'],
      group: [],
      kicker: ['text-kicker-sm', 'text-gray-950', 'group-[&:is(.bg-red,.bg-blue,.bg-black)]/box:text-white'],
      media: [],
      sticker: [],
      tag: ['text-tag-sm', 'mb-0.5', 'text-red', 'group-[&:is(.bg-red,.bg-blue,.bg-black)]/box:text-yellow'],
      ticker: [],
      title: ['text-gray-950', 'group-[&:is(.bg-red,.bg-blue,.bg-black)]/box:text-white'],
    },
    variants: {
      orientation: {
        vertical: {
          base: ['relative', 'gap-0'],
          group: ['gap-y-1', 'm-0', 'p-3', 'sm:p-4', 'md:p-6'],
          sticker: ['right-3', '-bottom-6', 'p-0'],
          ticker: ['col-span-full', 'flex', 'max-w-full', 'flex-row', 'items-center', 'uppercase'],
          title: [
            'text-headline-lg',
            'grid-cols-4:text-headline-lg',
            'grid-cols-7:text-headline-xl',

            'sm:text-headline-lg',
            'sm:grid-cols-4:text-headline-xl',
            'sm:grid-cols-7:text-headline-2xl',

            'md:text-headline-xl',
            'md:grid-cols-4:text-headline-2xl',
            'md:grid-cols-7:text-headline-3xl',
          ],
        },
      },
    },
  }),
  {
    embed: {
      ...BaseArticleTeaserTheme.embed,
      sticker: tw.theme({
        extend: HantQuoteboxTheme,
        slots: {
          base: ['float-none', 'w-20', 'h-20', 'md:w-28', 'md:h-28', 'm-0'],
          quote: [],
        },
      }),
      image: tw.theme({
        extend: BaseArticleTeaserTheme.embed.image,
        slots: {
          base: ['rounded-none'],
        },
      }),
    },
  },
);
